const pickupSwiper = new Swiper('.pickup-swiper', {
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.pickup-next',
    prevEl: '.pickup-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
});

const swiperSlides = document.getElementsByClassName('swiper-slide');
const mediaQuery = window.matchMedia('(min-width: 1024px)');
let deptSwiperAm;
let deptSwiperPm;

window.addEventListener(
  'load',
  () => {
    if (!mediaQuery.matches) {
      createSwiper();
    }
  },
  false
);

window.addEventListener(
  'resize',
  () => {
    if (mediaQuery.matches) {
      if (deptSwiperAm) deptSwiperAm.destroy(false, true);
      if (deptSwiperPm) deptSwiperPm.destroy(false, true);
    } else {
      createSwiper();
    }
  },
  false
);

const createSwiper = () => {
  deptSwiperAm = new Swiper('.swiper-department-am', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
  });
  deptSwiperPm = new Swiper('.swiper-department-pm', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
  });
};

$(function () {
  $('.visual-slide-list').slick({
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    // pauseOnHover: true,
    // pauseOnFocus: true,
    fade: true,
    speed: 4000,
  });
  setTimeout(function () {
    $('.visual-slide-list').slick('slickPlay');
  }, 4000);
  $(window).on('scroll', function () {
    var scroll = $(window).scrollTop();
    var n = scroll / 3;
    $('.visual-slide-list').css('transform', 'translate3d(0,' + n + 'px,0)');
  });
});
